import WebsocketHelper from "./WebsocketHelper";

class TestHelper {
  private static buttonElement;

  public static randomName : string;
  private static randomEmail : string = "test@test.com";
  
  private static firstNames =  ["Adam", "Alex", "Aaron", "Ben", "Carl", "Dan", "David", "Edward", "Fred", "Frank", "George", "Hal", "Hank", "Ike", "John", "Jack", "Joe", "Larry", "Monte", "Matthew", "Mark", "Nathan", "Otto", "Paul", "Peter", "Roger", "Roger", "Steve", "Thomas", "Tim", "Ty", "Victor", "Walter"];   
  private static lastNames = ["Anderson", "Ashwoon", "Aikin", "Bateman", "Bongard", "Bowers", "Boyd", "Cannon", "Cast", "Deitz", "Dewalt", "Ebner", "Frick", "Hancock", "Haworth", "Hesch", "Hoffman", "Kassing", "Knutson", "Lawless", "Lawicki", "Mccord", "McCormack", "Miller", "Myers", "Nugent", "Ortiz", "Orwig", "Ory", "Paiser", "Pak", "Pettigrew", "Quinn", "Quizoz", "Ramachandran", "Resnick", "Sagar", "Schickowski", "Schiebel", "Sellon", "Severson", "Shaffer", "Solberg", "Soloman", "Sonderling", "Soukup", "Soulis", "Stahl", "Sweeney", "Tandy", "Trebil", "Trusela", "Trussel", "Turco", "Uddin", "Uflan", "Ulrich", "Upson", "Vader", "Vail", "Valente", "Van Zandt", "Vanderpoel", "Ventotla", "Vogal", "Wagle", "Wagner", "Wakefield", "Weinstein", "Weiss", "Woo", "Yang", "Yates", "Yocum", "Zeaser", "Zeller", "Ziegler", "Bauer", "Baxster", "Casal", "Cataldi", "Caswell", "Celedon", "Chambers", "Chapman", "Christensen", "Darnell", "Davidson", "Davis", "DeLorenzo", "Dinkins", "Doran", "Dugelman", "Dugan", "Duffman", "Eastman", "Ferro", "Ferry", "Fletcher", "Fietzer", "Hylan", "Hydinger", "Illingsworth", "Ingram", "Irwin", "Jagtap", "Jenson", "Johnson", "Johnsen", "Jones", "Jurgenson", "Kalleg", "Kaskel", "Keller", "Leisinger", "LePage", "Lewis", "Linde", "Lulloff", "Maki", "Martin", "McGinnis", "Mills", "Moody", "Moore", "Napier", "Nelson", "Norquist", "Nuttle", "Olson", "Ostrander", "Reamer", "Reardon", "Reyes", "Rice", "Ripka", "Roberts", "Rogers", "Root", "Sandstrom", "Sawyer", "Schlicht", "Schmitt", "Schwager", "Schutz", "Schuster", "Tapia", "Thompson", "Tiernan", "Tisler" ];
  
  public static Init() {
    (window as any).TestHelper = TestHelper;
    
    //Listen for a button click to start the connection process
    TestHelper.buttonElement = document.getElementById("startButton");

    console.log(TestHelper.buttonElement);

    if (TestHelper.buttonElement) {
      TestHelper.buttonElement.addEventListener("click", TestHelper.onStartButtonClick)
    }
  }

  private static onStartButtonClick() {
    //Remove button listener
    TestHelper.buttonElement.removeEventListener("click", TestHelper.onStartButtonClick);
    TestHelper.buttonElement.disabled = true;

    console.log("Start button clicked")

    //Initiate the connection process
    TestHelper.Load();
  }

  private static async Load() {
    //Generate random first name
    TestHelper.randomName = `${TestHelper.getRandomFirst()} ${TestHelper.getRandomLast()}`;

    //Check if an override serverID is set in the URL
    let serverIDParam = WebsocketHelper.getQueryParam("s");
    
    //Connect to WS using the "loginCode" set in config, if an override is not specified in the URL
    //"loginCode" naming is used to allow compatibility with the showboat bot testing setup
    const connectResponse = await WebsocketHelper.connectToWebsocket(
      TestHelper.randomName,
      TestHelper.randomEmail,
      serverIDParam ? serverIDParam : (window as any).config.serverID
    );

    console.log("Connect response:", connectResponse);
  }

  private static getRandomFirst() {
    let index = Math.floor(
      Math.random() * Math.floor(TestHelper.firstNames.length - 1)
    );
    return TestHelper.firstNames[index];
  }

  private static getRandomLast() {
    let index = Math.floor(
      Math.random() * Math.floor(TestHelper.lastNames.length - 1)
    );
    return TestHelper.lastNames[index];
  }
}

export default TestHelper;