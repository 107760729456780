import React, { useState } from "react";
import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import TestHelper from "./utilities/TestHelper";
import WebsocketHelper from "./utilities/WebsocketHelper";
import StringHelper from "./utilities/StringHelper";

function App() {
  const [joined, setJoined] = useState(false);
  
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  
  const [responseLetter, setResponseLetter] = useState("");
  const [responseLetterError, setResponseLetterError] = useState(false);
  
  const [submitAutomatically, setSubmitAutomatically] = useState(true);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    TestHelper.Init();
  }, []);

  useEffect(() => {
    WebsocketHelper.pwWebsocketSDK.addMessageListener(StringHelper.ShowQuestionMessage, handleShowQuestion  )
  }, [])

  const handleSubmitAnswer = (e) => {
    let formHasError = false;
    if (!name.trim().length) {
      setNameError(true);
      formHasError = true;
    }

    if (responseLetter.trim().length !== 1) {
      setResponseLetterError(true);
      formHasError = true;
    }

    if (formHasError) return;

    WebsocketHelper.sendAnswer(responseLetter, name);
    setDisableSubmitButton(true);
  };

  const handleSubmitAutomaticallyChange = (e) => {
    if (e.target.checked) {
      setName("");
      setNameError(false);
      setResponseLetter("");
      setResponseLetterError(false);
    }
    
    setSubmitAutomatically(e.target.checked);
    WebsocketHelper.submitAutomatically = e.target.checked;
  }

  const handleShowQuestion = () => {
    setDisableSubmitButton(false);
  }

  const handleNameChange = (e) => {
    if (e.target.value.trim().length) {
      setNameError(false);
    } 

    setName(e.target.value);
  }

  const handleResponseLetterChange = (e) => {
    if (e.target.value.trim().length) {
      setResponseLetterError(false);
    }

    setResponseLetter(e.target.value);
  };

  const handleJoinTestClick = () => {
    setJoined(true);
  }

  return (
    <div className="App">
      <p
        style={{
          marginBottom: 50,
        }}
      >
        ARS Stress Test
      </p>

      <button
        style={{
          marginBottom: 30,
        }}
        id="startButton"
        onClick={handleJoinTestClick}
      >
        Join test
      </button>

      <label htmlFor="nameInput">Name</label>
      <input
        style={{
          marginBottom: 30,
        }}
        id="nameInput"
        type="text"
        value={name}
        disabled={submitAutomatically || disableSubmitButton}
        className={nameError ? "nameInput nameInputError" : "nameInput"}
        onChange={handleNameChange}
      />

      <label htmlFor="responseLetterInput">Response Letter</label>
      <input
        style={{
          marginBottom: 30,
        }}
        id="responseLetterInput"
        type="text"
        value={responseLetter}
        disabled={submitAutomatically || disableSubmitButton}
        className={
          responseLetterError
            ? "responseLetterInput responseLetterInputError"
            : "responseLetterInput"
        }
        onChange={handleResponseLetterChange}
      />

      <button
        id="submitAnswerButton"
        onClick={handleSubmitAnswer}
        disabled={disableSubmitButton || submitAutomatically}
      >
        Submit Answer
      </button>

      <label htmlFor="submitAutomaticallyCheckbox">Submit Automatically?</label>
      <input
        type="checkbox"
        checked={submitAutomatically}
        id="submitAutomaticallyCheckbox"
        onChange={handleSubmitAutomaticallyChange}
        disabled={!joined}
      />
    </div>
  );
}

export default App;
