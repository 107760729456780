import StringHelper from "./StringHelper";
import PWWebsocketSDK from "../sdk/pw__websocket_sdk";
import TestHelper from "./TestHelper";

class WebsocketHelper {
  static serverBaseURL = "wss://engine.showboat.live:3415";

  static pwWebsocketSDK = new PWWebsocketSDK();

  static showQuestionListener;
  static sendAnswerHandler;
  static timedOutListener;

  static currentState = "none";

  static needStoreIdentifyTimestamp = true;

  static submitAutomatically = true;

  static serverID;
  static nameToSend;
  static emailToSend;

  static chanceTo;

  static async connectToWebsocket(name, email, serverIDParam) {
    return new Promise(async (resolve) => {
      try {
        this.serverID = serverIDParam;
        this.nameToSend = name;
        this.emailToSend = email;

        console.log(
          "Connecting to websocket:",
          this.nameToSend,
          this.emailToSend,
          this.serverID
        );

        const res = await this.pwWebsocketSDK.connectToGameServer(
          this.serverBaseURL,
          serverIDParam,
          ""
        );

        if (res) {
          //Set up listeners
          this.showQuestionListener = this.handleShowQuestion;
          this.showQuestionListener = this.showQuestionListener.bind(this);
          this.pwWebsocketSDK.addMessageListener(
            StringHelper.ShowQuestionMessage,
            this.showQuestionListener
          );

          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        console.log("Error connecting to websocket:", error);
        resolve(false);
      }
    });
  }

  //Helpers
  static handleShowQuestion() {
    //If submit automatically is enabled, submit after a random delay
    if (WebsocketHelper.submitAutomatically) {
      const answerDelay = this.getAnswerDelay();

      //Send random answer after adhering to the randomSendAnswerDelay
      setTimeout(() => {
        WebsocketHelper.sendAnswer();
      }, answerDelay);
    }
  }

  static getAnswerDelay() {
    //Get random value between fastestAnswerTime and randomSendAnswerDelay
    // Multiply by 1000 to convert to MS
    let fastestAnswerTime = (window as any).config.fastestAnswerTime;
    let randomSendAnswerDelay = (window as any).config.randomSendAnswerDelay;

    //Convert fastestAnswerTime (in seconds) to MS
    let fastestAnswerTimeMS = fastestAnswerTime * 1000;

    //Add random # of MS between randomSendAnswerDelay and fastestAnswerTime
    let randomMS =
      fastestAnswerTimeMS +
      Math.random() * (randomSendAnswerDelay - fastestAnswerTime) * 1000;

    return randomMS;
  }

  static sendAnswer(answer?: string, name?: string) {
    //Answer and name will be provided if submitted manually
    //Otherwise, a random letter and the randomly-generated name will be used
    this.pwWebsocketSDK.sendWebsocketMessage(StringHelper.SendAnswerMessage, {
      answer: answer ? answer : WebsocketHelper.randomLetter("abcd"),
      name: name ? name : WebsocketHelper.nameToSend,
    });
  }

  static handleAppLoad() {}

  static getQueryParam(param) {
    return this.pwWebsocketSDK.getQueryParam(param);
  }

  static randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  //Returns a random letter between a-d
  //Options is a string of letter options. Ex: "abcd"
  static randomLetter(options: string) {
    var randomIndex = Math.floor(Math.random() * options.length);
    var randomLetter = options[randomIndex];
    return randomLetter;
  }
}

export default WebsocketHelper;
